import React from 'react';
import AllocationsTableExample from './AllocationsTableExample';

export default function () {
    return (
        <div>
            <div>
                <h2>Import Buy Plan Files</h2>
                <p>
                    After uploading the assortment files several other files are necessary before the assembly of the buy
                    plan can begin. Below you will find the details of these buy plan files.
                </p>
                <div className="ImportBuyPlanFiles__fileSection">
                    <h3>Import Minimum Buy File</h3>
                    <p>
                        The sheet should be visible and named <code>Sheet1</code>. Please note: There can only be one minimum
                        buy entry per store number / category / division / product grade combination.
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">STORE NUMBER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CATEGORY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">DIVISION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">PRODUCT GRADES</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>A or B or C</td>
                            </tr>
                            <tr>
                                <th scope="row">PARTNER CONFIRMED MINIMUMS</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                    <h4>Modifying/Deleting the Minimum Buy file</h4>
                    <p>
                        Re-uploading the Minimum Buy file will update the minimum buys for the given channel and season. In
                        this case, all affected material clusters will be marked <code>UPDATED BY FILE UPLOAD</code>.
                    </p>
                    <p>Deleting the Minimum Buy file will remove minimum buys for the given channel and season.</p>
                </div>
                <div className="ImportBuyPlanFiles__fileSection">
                    <h3>Import Hindsight File</h3>
                    <p>
                        <strong>
                            The hindsight file must be in csv format when uploading the file. It is very important to make
                            sure that it is properly comma separated.
                        </strong>
                    </p>
                    <p>
                        The data in the hindsight file is used to calculate the WOS and total units from last year. Matching
                        happens using these steps:
                    </p>
                    <ol>
                        <li>Like4like from cluster reference</li>
                        <li>Like4like from cluster reference with store like4like</li>
                        <li>Material/color</li>
                        <li>Material/color with store like4like</li>
                        <li>Lik4Like from merchandise file</li>
                        <li>Lik4Like from merchandise file with store like4like</li>
                        <li>Style</li>
                        <li>Style with store like4like</li>
                        <li>Category/division/family/model</li>
                        <li>Category/division/family/model with store like4like</li>
                        <li>Category/division/family</li>
                        <li>Category/division/family with store like4like</li>
                        <li>Category/division/model</li>
                        <li>Category/division/model with store like4like</li>
                        <li>Category/division</li>
                        <li>Category/division with store like4like</li>
                    </ol>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">YEAR</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>TY or LY</td>
                            </tr>
                            <tr>
                                <th scope="row">Store No</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Retailer</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>The partner name</td>
                            </tr>
                            <tr>
                                <th scope="row">Material Code</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Style Code</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Sales Units</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Weeks on sale</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">ROS</th>
                                <td>Decimal</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Sell Thru</th>
                                <td>Decimal</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Invest Units</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Sales Net Revenue</th>
                                <td>Decimal</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">DIVISION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CONDENSED CATEGORY FINAL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">FAMILY FINAL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA FAMILY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MODEL FINAL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">AGE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">GENDER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">COLOR DESCRIPTION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">ITEM NAME FINAL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>The material description</td>
                            </tr>
                            <tr>
                                <th scope="row">AA/ICON</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">GEL/QUICKSTRIKE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">SILHOUETTE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CC SEGMENT</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">LAUNCH FLAG</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>
                                    YES, NO or empty. NOTE: YES values will be mapped to L launch flag in hindsight data, and
                                    NO values will be mapped to D launch flag in hindsight data. If you do not wish to
                                    include a launch flag, leave this field blank.
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">CC CONSUMER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Must be one of the following valid consumers: WOMENS, MENS, KIDS, JORDAN</td>
                            </tr>
                            <tr>
                                <th scope="row">MARGIN USD</th>
                                <td>Decimal</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MARGIN PERCENTAGE</th>
                                <td>Decimal</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">BRAND</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Must be one of the following valid brands: NIKE, JORDAN</td>
                            </tr>
                            <tr>
                                <th scope="row">FRANCHISE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MERCH CLASS</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CONSUMER USE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA DIMENSION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA SILO</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA SUB-FRANCHISE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA COLLECTION NAME</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">SILHOUETTE TYPE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">3RD PARTY VENDOR (LICENSEE)</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">FIELDS OF PLAY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">FEATURES (SUSTAINABILITY TIER)</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA FOOTBALL PLATES</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA BUSINESS MODEL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                    <h4>Modifying/Deleting the Hindsight file</h4>
                    <p>
                        Re-uploading the Hindsight file will reset Hindsight data and L4L matches. In this case, all affected
                        material clusters will be marked <code>UPDATED BY FILE UPLOAD</code>.
                    </p>
                    <p>
                        Deleting the Hindsight file will reset Hindsight data and L4L matches. In this case, all affected
                        material clusters will be reset to <code>NOT REVIEWED</code>.
                    </p>
                </div>
                <div className="ImportBuyPlanFiles__fileSection">
                    <h3>Import Allocation File</h3>
                    <p>
                        You can upload an allocations file to set the sell through and compare the buy plan with allocations
                        defined within 1Plan.
                    </p>
                    <p>Allocations are defined in a hierarchical order:</p>
                    <ol>
                        <li>Allocations at a partner/category/model level</li>
                        <li>Allocations at a partner/category/model/gender level</li>
                        <li>Allocations at a partner/category/model/age level</li>
                        <li>Allocations at a partner/category/model/gender/age level</li>
                        <li>Allocations at a partner/category/model/store level</li>
                        <li>Allocations at a partner/category/model/gender/store level</li>
                        <li>Allocations at a partner/category/model/age/store level</li>
                        <li>Allocations at a partner/category/model/gender/age/store level</li>
                        <li>Allocations at a partner/category/model/material level</li>
                        <li>Allocations at a partner/category/model/gender/material level</li>
                        <li>Allocations at a partner/category/model/age/material level</li>
                        <li>Allocations at a partner/category/model/gender/agematerial level</li>
                        <li>Allocations at a partner/category/model/store/material level</li>
                        <li>Allocations at a partner/category/model/gender/age/store/material level</li>
                    </ol>
                    <p>
                        It&apos;s not possible to define allocations at a lower level in the hierarchy if an allocation at a
                        higher level exists. Or have a material with both an allocation on AA and AF/QS. For example:
                    </p>
                    <AllocationsTableExample />
                    <p>
                        The allocations file must have a visible sheet named <code>SHEET 1</code> and contain the following:
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">PARTNER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MODEL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CATEGORY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">GENDER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">AGE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">STORE NUMBER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MATERIAL CODE</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>XXXXXX-XXX where X is A-Z,0-9</td>
                            </tr>
                            <tr>
                                <th scope="row">AA UNITS</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>Defaults to 0 if empty. Can not have units on material if AF/QS already has units.</td>
                            </tr>
                            <tr>
                                <th scope="row">AF/QS UNITS</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>Defaults to 0 if empty. Can not have units on material if AF/QS already has units.</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        After uploading an allocation file, the sell through for all relevant buys will be adjusted to reach
                        the allocated value.
                    </p>
                    <p>
                        If the ROS is very low, it can happen that the Presentation Stocks will be a bit higher than the
                        allocated value, due to the rounding of values.
                    </p>
                    <p>
                        If the allocation units are very high and the sell through goes below 1%, the sell through will
                        default to 1% instead, rather than 0% to avoid division by zero errors.
                    </p>
                    <p>The sell through for buys with an ROS of 0 will not be updated.</p>
                    <h4>Modifying/Deleting the Allocation file</h4>
                    <p>
                        Re-uploading the Allocation file will update the allocations for the given channel and season. This
                        will also re-calculate the buy sell-through value for the provided materials.
                    </p>
                    <p>Deleting the Allocation file will remove all existing allocations for the season.</p>
                </div>
                <div className="ImportBuyPlanFiles__fileSection">
                    <h3>Import Seasonal Hindsight File</h3>
                    <p>
                        This file upload is required for all channels to get the hindsight seasonal option counts. This file
                        should only be uploaded after the Hindsight File has been uploaded. The Seasonal Hindsight file is
                        used to map additional attributes that are not available in the hindsight data. Uploading this file
                        will update the following hindsight data for the provided year: type of order, seasonal buy wholesale
                        value, and seasonal buy units.
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">MATERIAL CODE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">YEAR</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>LY or LLY</td>
                            </tr>
                            <tr>
                                <th scope="row">TYPE OF ORDER (only for Digital channel)</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>AA, AF, NBY or QS</td>
                            </tr>
                            <tr>
                                <th scope="row">AF $ (only for Digital channel)</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>Minimum 0</td>
                            </tr>
                            <tr>
                                <th scope="row">AF UNIT (only for Digital channel)</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>Minimum 0</td>
                            </tr>
                            <tr>
                                <th scope="row">SEASONAL FLAG</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>
                                    Y, N or not provided. This is used to determine seasonal option counts for LY/LLY in
                                    reports.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>Modifying/Deleting the Seasonal Hindsight file</h4>
                    <p>
                        Re-uploading or deleting the Seasonal Hindsight file will impact the data that was provided in the
                        existing upload for the given channel and season. This includes replacing or removing hindsight type
                        of order, seasonal buy wholesale value, and seasonal buy units.
                    </p>
                    <p>
                        <strong>Note:</strong>
                        <br />
                        If the Hindsight file is deleted or re-upload with additional materials, the Seasonal Hindsight file
                        should also be either deleted and uploaded again or re-uploaded with new materials possibly added in
                        Hindsight file re-upload.
                    </p>
                </div>
                <div className="ImportBuyPlanFiles__fileSection">
                    <h3>Import Sales Targets File</h3>
                    <p>
                        This file upload is required for all channels. For channels other than digital, this file should only
                        be uploaded after the Option Counts file has been uploaded. The sales targets file is used to set
                        initial sales targets at both a store and gender/segment/category/division level.
                    </p>
                    <p>
                        The sales targets file must have a visible sheet named <code>Store</code> and contain the following:
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Partner</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Store Number</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Store Name</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Retail Sales Target</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Minimum 0</td>
                            </tr>
                            <tr>
                                <th scope="row">Open to Buy</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Minimum 0</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        The sales targets file must also a visible sheet named <code>Attributed Sales Targets</code> and
                        contain the following:
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Partner</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Consumer</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Segment</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Category</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Division</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">Retail Sales Target</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Minimum 0</td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>Modifying/Deleting the Sales Target Hindsight file</h4>
                    <p>
                        Re-uploading a Sales Targets file will add or update any new sales targets provided in the file for a
                        given season and channel. Deleting a sales targets file will remove all store and category division
                        sales targets for a given season and channel.
                    </p>
                </div>
            </div>
        </div>
    );
}
