import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    TopLineFilterKeys,
    TopLineFilterSelections,
    LoadingStatus,
    dynamicRollupsSlicers,
    dynamicRollupsRows,
    DynamicRollupsRowConfig,
    Channel,
    UserProfile,
    Season,
} from 'buyplan-common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import TopLineFilters from '../Filters/ToplineFilters/ToplineFilters';
import Loader from '../Loader/Loader';
import './DynamicRollups.scss';
import useUserSettings from '../../selectors/useUserSettings';
import { getDynamicRollups } from '../../actions/dynamicRollups';
import { useSelector } from '../../store/reducers';
import Button from '../Button/Button';
import { PageView } from '../../constants/appConfig';
import SlicingFilters from '../Filters/SlicingFilters/SlicingFilters';
import SettingsButton from '../SettingsButton/SettingsButton';
import { useRequestFunction } from '../../api/useRequest';
import { getMetaData } from '../../services/dynamicRollupsService';
import { cleanupRollupsFiltersAction } from '../../actions/user';
import useDynamicRollups from '../../selectors/useDynamicRollups';
import { prepareDynamicRollupsExport } from '../../services/excelService';
import useUserProfile from '../../selectors/useUserProfile';
import DynamicRollupsTable from './DynamicRollupsTable/DynamicRollupsTable';
import DynamicRollupsSettings from './DynamicRollupsSettings/DynamicRollupsSettings';

const checkRequiredFilters = (activeTopLineFilters: TopLineFilterSelections, activeSlicers: TopLineFilterKeys) => {
    const requiredKeys = ['partner', 'brand', 'consumer', 'division', 'emeaDimension'];
    const hasRequiredFilters = requiredKeys.every((key) => {
        const filterValue = activeTopLineFilters[key as keyof TopLineFilterSelections];
        return key in activeTopLineFilters && filterValue && filterValue.length > 0;
    });
    const requiredSlicers = activeSlicers.length > 0;

    return hasRequiredFilters && requiredSlicers;
};

function DynamicRollups() {
    const dispatch = useDispatch();
    const visibleDynamicRollupsColumnKeys = useSelector((state) => state.user.settings.visibleDynamicRollupsColumnKeys);
    const { activeChannelId, activeSeasonId } = useUserSettings();
    const [slicerColumns, setSlicerColumns] = useState([] as DynamicRollupsRowConfig[]);
    const getMetaDataCallback = useCallback(
        () => getMetaData({ channelId: activeChannelId, seasonId: activeSeasonId }),
        [activeChannelId, activeSeasonId]
    );
    const [filtersLoading, , filtersData, fetchMetaData] = useRequestFunction(getMetaDataCallback);

    const activeTopLineFilters = useSelector(
        (state) =>
            state.user.settings.activeTopLineFilters?.[`${activeChannelId}|${PageView.dynamicRollups}`]?.mainFilters ?? {}
    );

    const activeSlicers = useSelector(
        (state) => state.user.settings.activeTopLineFilters?.[`${activeChannelId}|${PageView.dynamicRollups}`]?.slicers ?? []
    );

    // Adding extra data to support the file export.
    const { allSeason } = useSelector((state) => state.season);
    const { channels } = useUserProfile() as UserProfile;
    const { data, loadingStatus } = useDynamicRollups();

    useEffect(() => {
        if (!filtersData) return;
        dispatch(cleanupRollupsFiltersAction(filtersData.filters, activeChannelId, PageView.dynamicRollups));
    }, [filtersData, dispatch, activeChannelId]);

    useEffect(() => {
        if (!activeChannelId || !activeSeasonId) return;
        fetchMetaData();
    }, [fetchMetaData, activeChannelId, activeSeasonId]);

    const loadDynamicRollups = () => {
        dispatch(getDynamicRollups());
        const selectedSlicerColumns = activeSlicers.reduce((acc, curSlicer: string) => {
            const slicer = dynamicRollupsSlicers.find((s: DynamicRollupsRowConfig) => s.key === curSlicer);
            if (slicer) {
                acc.push(slicer);
            }
            return acc;
        }, [] as DynamicRollupsRowConfig[]);
        setSlicerColumns(selectedSlicerColumns);
    };

    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const toggleSettings = useCallback(() => {
        setSettingsOpen(!isSettingsOpen);
    }, [setSettingsOpen, isSettingsOpen]);

    const selectedDynamicColumns = dynamicRollupsRows.filter(
        (col: DynamicRollupsRowConfig) => visibleDynamicRollupsColumnKeys.includes(col.key) || col.isFixed
    );

    const handleExport = () => {
        const channelName = channels.filter((ch: Channel) => ch.id === activeChannelId)[0].name ?? activeChannelId;
        const seasonName = allSeason?.filter((season: Season) => season.id === activeSeasonId)[0].name ?? activeSeasonId;
        const filename = `Roll_up_${channelName}_${seasonName}`;
        prepareDynamicRollupsExport(slicerColumns, selectedDynamicColumns, data, filename);
    };

    return (
        <div className="DynamicRollups">
            <div className="DynamicRollups__filterContainer">
                <h1>DYNAMIC ROLLUPS</h1>
                <TopLineFilters
                    filtersLoading={filtersLoading}
                    filtersData={filtersData}
                    activeFilters={activeTopLineFilters}
                />
                <SlicingFilters activeSlicers={activeSlicers} />
                <div className="DynamicRollups__actions-container">
                    <Button
                        onClick={loadDynamicRollups}
                        disabled={!checkRequiredFilters(activeTopLineFilters, activeSlicers)}
                    >
                        Load Report
                    </Button>
                    {data?.objects?.length && loadingStatus === LoadingStatus.success && (
                        <Button id="rollups-export-button" onClick={handleExport} className="Button">
                            <FontAwesomeIcon icon={faFileDownload as IconProp} />
                            Download Report in Excel
                        </Button>
                    )}
                </div>
            </div>
            <div>
                <div className="DynamicRollups__container">
                    {isSettingsOpen && <DynamicRollupsSettings onClose={toggleSettings} />}

                    <div className="DynamicRollups__table-container">
                        {!data?.objects?.length && loadingStatus === LoadingStatus.initial && (
                            <div className="DynamicRollups__no-data">Click to load report data.</div>
                        )}
                        {loadingStatus === LoadingStatus.loading ? <Loader /> : null}
                        {!data?.objects?.length && loadingStatus === LoadingStatus.success && (
                            <div className="DynamicRollups__no-data">No report data found, try changing filters</div>
                        )}
                        {data?.objects?.length > 0 && loadingStatus === LoadingStatus.success && (
                            <>
                                <div className="DynamicRollups__settings-container">
                                    <h3>Report</h3>
                                    <SettingsButton onClick={toggleSettings} />
                                </div>
                                <DynamicRollupsTable
                                    report={data}
                                    slicerColumns={slicerColumns}
                                    dynamicRollupsColumns={selectedDynamicColumns}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DynamicRollups;
