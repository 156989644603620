import React from 'react';
import { NavLink, Route, Switch, Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Logo from '../../Logo/Logo';
import './BuyplanDocumentation.scss';
import ImportBuyplanFiles from './ImportBuyplanFiles';
import Support from './Support';
import ExchangeRates from './ExchangeRates';
import RolesRights from './RolesRights';
import MinimumBuys from './MinimumBuys';
import Clustering from './Clustering';
import DummyCodes from './DummyCodes';
import Prices from './Prices';
import SalesTargets from './SalesTargets';
import LateAdds from './LateAdds';
import AssortmentManagement from './AssortmentManagement';
import Glossary from './Glossary';
import ImportAssortmentFiles from './ImportAssortmentFiles';
import MissingValues from './MissingValues';
import UserSettings from './UserSettings';
import TopStylesReport from './TopStylesReport';
import MarginImpact from './MarginImpact';
import AFPercentage from './AFPercentage';
import ROSCalculations from './ROSCalculations';
import Totals from './Totals';
import StartEndWeeksCalculation from './StartEndWeeksCalculation';
import ViewingOptionCounts from './ViewingOptionCounts';

function BuyPlan() {
    return (
        <div>
            <Logo app="buyplan" to="/buyplan/documentation" />
            <div className="Overview">
                <h2>Overview</h2>
                <p>
                    Welcome to the BuyPlan application. Our goal with this application is to bring you the most friendly
                    application to not only set you forward in successfully completing our buy process but also delivering
                    with speed and accuracy.
                </p>
                <h2>Getting Started</h2>
                <p>To get this process started we require a few files for managing the assorment:</p>
                <ul>
                    <li>Option Count File</li>
                    <li>Merchandise Files</li>
                </ul>
                <p>
                    All of these files can be uploaded <Link to="/buyplan/manage-assortment/import">here</Link>. For more
                    details on the process and structure of these files{' '}
                    <Link to="/buyplan/documentation/import-assortment-files">go here</Link>.
                </p>
                <p>For creating the buy plan some additional files are required:</p>
                <ul>
                    <li>Minimum Buy File (Upcoming)</li>
                    <li>Hindsight File (Upcoming)</li>
                </ul>
                <p>
                    These files can be uploaded <Link to="/buyplan/import-files">here</Link>. For more details on the process
                    and structure of these files <Link to="/buyplan/documentation/import-buyplan-files">go here</Link>.
                </p>
                <h2>Managing the Assortment</h2>
                <p>
                    After you have uploaded the option count and merchandise files, you are ready to begin managing your
                    assortment. If you go to the <Link to="/buyplan/manage-assortment">assortment</Link> tab you can get
                    started. You can find more specific documentation to managing the assortment{' '}
                    <Link to="/buyplan/documentation/assortment">here</Link>.
                </p>
            </div>
        </div>
    );
}

export default function BuyPlanDocumentation() {
    return (
        <div className="BuyplanDocumentation">
            <Container>
                <Row>
                    <Col xs={{ span: 9, offset: 3 }}>
                        <h1>DOCUMENTATION</h1>
                    </Col>
                    <Col xs={{ span: 2, offset: 1 }}>
                        <nav className="BuyplanDocumentation__Navigation">
                            <NavLink to="/buyplan/documentation" exact>
                                Home
                            </NavLink>
                            <NavLink to="/buyplan/documentation/glossary">Glossary</NavLink>
                            <NavLink to="/buyplan/documentation/roles-rights">Roles &amp; Rights</NavLink>
                            <NavLink to="/buyplan/documentation/exchange-rates">Exchange Rates</NavLink>
                            <NavLink to="/buyplan/documentation/support">Support</NavLink>
                            <NavLink to="/buyplan/documentation/user-settings">User Settings</NavLink>
                            <NavLink to="/buyplan/documentation/dummy-codes">Dummy Codes</NavLink>
                            <NavLink to="/buyplan/documentation/prices">Prices</NavLink>
                            <hr />
                            <h6 className="BuyplanDocumentation__SectionTitle">CALCULATIONS</h6>
                            <NavLink to="/buyplan/documentation/start-end-weeks">Weeks and Flows</NavLink>
                            <NavLink to="/buyplan/documentation/ros-calculations">ROS Logic</NavLink>
                            <hr />
                            <h6 className="BuyplanDocumentation__SectionTitle">ASSORTMENT</h6>
                            <NavLink to="/buyplan/documentation/import-assortment-files">Import Assortment Files</NavLink>
                            <NavLink to="/buyplan/documentation/assortment">Manage Assortment</NavLink>
                            <NavLink to="/buyplan/documentation/late-adds">Import Late adds</NavLink>
                            <NavLink to="/buyplan/documentation/viewing-option-counts">Viewing Option Counts</NavLink>
                            <hr />
                            <h6 className="BuyplanDocumentation__SectionTitle">BUY PLAN</h6>
                            <NavLink to="/buyplan/documentation/import-buyplan-files">Import Buy Plan Files</NavLink>
                            <NavLink to="/buyplan/documentation/sales-targets">Sales Targets</NavLink>
                            <NavLink to="/buyplan/documentation/margin-impact">Margin Impact</NavLink>
                            <NavLink to="/buyplan/documentation/af-percentage">AF %</NavLink>
                            <NavLink to="/buyplan/documentation/clustering">Clustering</NavLink>
                            <NavLink to="/buyplan/documentation/minimum-buys">Minimum Buys</NavLink>
                            <NavLink to="/buyplan/documentation/ros-calculations">ROS Logic</NavLink>
                            <NavLink to="/buyplan/documentation/totals">Totals</NavLink>
                            <hr />
                            <h6 className="BuyplanDocumentation__SectionTitle">REPORTS</h6>
                            <NavLink to="/buyplan/documentation/top-styles">Top 15 Styles</NavLink>
                            <hr />
                            <NavLink to="/buyplan/documentation/missing-values">Missing Values</NavLink>
                        </nav>
                    </Col>
                    <Col xs={{ span: 9, offset: 0 }}>
                        <Switch>
                            <Route path="/buyplan/documentation/glossary" component={Glossary} />
                            <Route path="/buyplan/documentation/import-assortment-files" component={ImportAssortmentFiles} />
                            <Route path="/buyplan/documentation/import-buyplan-files" component={ImportBuyplanFiles} />
                            <Route path="/buyplan/documentation/late-adds" component={LateAdds} />
                            <Route path="/buyplan/documentation/viewing-option-counts" component={ViewingOptionCounts} />
                            <Route path="/buyplan/documentation/start-end-weeks" component={StartEndWeeksCalculation} />
                            <Route path="/buyplan/documentation/assortment" component={AssortmentManagement} />
                            <Route path="/buyplan/documentation/roles-rights" component={RolesRights} />
                            <Route path="/buyplan/documentation/dummy-codes" component={DummyCodes} />
                            <Route path="/buyplan/documentation/prices" component={Prices} />
                            <Route path="/buyplan/documentation/sales-targets" component={SalesTargets} />
                            <Route path="/buyplan/documentation/margin-impact" component={MarginImpact} />
                            <Route path="/buyplan/documentation/af-percentage" component={AFPercentage} />
                            <Route path="/buyplan/documentation/exchange-rates" component={ExchangeRates} />
                            <Route path="/buyplan/documentation/support" component={Support} />
                            <Route path="/buyplan/documentation/clustering" component={Clustering} />
                            <Route path="/buyplan/documentation/minimum-buys" component={MinimumBuys} />
                            <Route path="/buyplan/documentation/ros-calculations" component={ROSCalculations} />
                            <Route path="/buyplan/documentation/totals" component={Totals} />
                            <Route path="/buyplan/documentation/user-settings" component={UserSettings} />
                            <Route path="/buyplan/documentation/top-styles" component={TopStylesReport} />
                            <Route path="/buyplan/documentation/missing-values" component={MissingValues} />
                            <Route component={BuyPlan} />
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
