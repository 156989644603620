"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dynamicRollupsRows = exports.dynamicRollupsSlicers = void 0;
const formatters_1 = require("../helpers/formatters");
const filterTypes_1 = require("../types/filterTypes");
const labels_1 = require("../helpers/labels");
exports.dynamicRollupsSlicers = filterTypes_1.filterOrder.map((filter) => ({
    key: filter,
    getValue: (value) => value,
    isGroupStart: true,
    groupStartHeader: (0, labels_1.getLabel)(filter),
    label: (0, labels_1.getLabel)(filter),
    width: 110,
    type: 'fixed',
}));
exports.dynamicRollupsRows = [
    // REVENUE
    {
        key: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Revenue',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSalesNetRevenueSum',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSalesNetRevenueSum',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'salesRetailValueUSDGrowthPercentage',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'salesRetailValueUSDGrowthPercentageLLY',
        groupKey: 'salesRetailValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 140,
        type: 'scrollable',
    },
    // TOTAL REVENUE
    {
        key: 'salesRetailValueUSDPercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: '% Total Revenue',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSalesRetailValueUSDPercentageOfTotal',
        groupKey: 'salesRetailValueUSDPercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSalesRetailValueUSDPercentageOfTotal',
        groupKey: 'salesRetailValueUSDPercentageOfTotal',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    // PRODUCTIVITY
    {
        key: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Productivity',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'productivityGrowthPercentage',
        groupKey: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'lastYearProductivity',
        groupKey: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearProductivity',
        groupKey: 'productivity',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    // SEASONAL OPTION COUNTS
    {
        key: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Seasonal Option Counts',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'seasonalOptionCountsGrowthPercentage',
        groupKey: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'lastYearSeasonalOptionCounts',
        groupKey: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSeasonalOptionCounts',
        groupKey: 'seasonalOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    // TRADED OPTION COUNTS
    {
        key: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY',
        isGroupStart: true,
        groupStartHeader: 'Traded Option Counts',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'tradedOptionCountsGrowthPercentage',
        groupKey: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'lastYearTradedOptionCounts',
        groupKey: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearTradedOptionCounts',
        groupKey: 'tradedOptionCounts',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    // SALES UNITS
    {
        key: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sales Units',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSalesUnitsSum',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSalesUnitsSum',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthPercentage',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'salesUnitsGrowthPercentageLLY',
        groupKey: 'salesUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 140,
        type: 'scrollable',
    },
    // SALES ASP
    {
        key: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sales ASP',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearAverageSellingPrice',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearAverageSellingPrice',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain, false),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'averageSellingPriceGrowthPercentage',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'averageSellingPriceGrowthPercentageLLY',
        groupKey: 'averageSellingPrice',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 140,
        type: 'scrollable',
    },
    // ST (SELL THROUGH)
    {
        key: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        getTotalsValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Sell Through %',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearSellThrough',
        groupKey: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        getTotalsValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearSellThrough',
        groupKey: 'sellThrough',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        getTotalsValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    // BOP
    {
        key: 'beginningOfPeriodUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'BOP',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    // Open To Buy Units
    {
        key: 'openToBuyUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'OTB Units',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'aaOpenToBuyUnitsSum',
        groupKey: 'openToBuyUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AA',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'afOpenToBuyUnitsSum',
        groupKey: 'openToBuyUnitsSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AF',
        width: 110,
        type: 'scrollable',
    },
    // BUY WHOLESALE VALUE $
    {
        key: 'buyWholesaleValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Buy Wholesale Value $',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'aaBuyWholesaleValueUSDSum',
        groupKey: 'buyWholesaleValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AA',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'afBuyWholesaleValueUSDSum',
        groupKey: 'buyWholesaleValueUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY AF',
        width: 110,
        type: 'scrollable',
    },
    // TTL Stocks
    {
        key: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'TTL Stocks',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearInvestUnitsSum',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearInvestUnitsSum',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'presentationStocksVsInvestUnitsGrowthPercentage',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'presentationStocksVsInvestUnitsLLYGrowthPercentage',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY % Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'presentationStocksVsInvestUnitsGrowth',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY UNITS Δ',
        width: 160,
        type: 'scrollable',
    },
    {
        key: 'presentationStocksVsInvestUnitsLLYGrowth',
        groupKey: 'presentationStocksSum',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY UNITS Δ',
        width: 160,
        type: 'scrollable',
    },
    // Margin USD
    {
        key: 'marginUSDSum',
        isGroupStart: true,
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'TY',
        groupStartHeader: 'Margin $',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearMarginUSDSum',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearMarginUSDSum',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'marginUSDVariance',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LY $ Δ',
        width: 140,
        type: 'scrollable',
    },
    {
        key: 'marginUSDVarianceLLY',
        groupKey: 'marginUSDSum',
        getValue: (value, plain = false) => (0, formatters_1.formatUSDOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY VS LLY $ Δ',
        width: 140,
        type: 'scrollable',
    },
    // Margin Percentage
    {
        key: 'marginPercentage',
        isGroupStart: true,
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY',
        groupStartHeader: 'Margin %',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'lastYearMarginPercentage',
        groupKey: 'marginPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'LY',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'lastLastYearMarginPercentage',
        groupKey: 'marginPercentage',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'LLY',
        width: 110,
        type: 'scrollable',
    },
    // Allocation units
    {
        key: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        isGroupStart: true,
        label: 'TY',
        groupStartHeader: 'Allocation Units',
        width: 110,
        type: 'scrollable',
        isFixed: true,
    },
    {
        key: 'aaAllocationUnits',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AA',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'afQSAllocationUnits',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        label: 'TY AF/QS',
        width: 110,
        type: 'scrollable',
    },
    {
        key: 'allocationVsOpenToBuyGrowthPercentage',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatPercentageOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY Allocation VS Open To Buy % Δ',
        width: 280,
        type: 'scrollable',
    },
    {
        key: 'allocationVsOpenToBuyGrowth',
        groupKey: 'totalAllocationUnits',
        getValue: (value, plain = false) => (0, formatters_1.formatNumberOrDash)(value, plain),
        hasNegativeValues: true,
        label: 'TY Allocation VS Open To Buy Units Δ',
        width: 280,
        type: 'scrollable',
    },
];
