import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import { DynamicRollupsRowConfig } from 'buyplan-common';
import HeaderColumn from '../../HeaderColumn/HeaderColumn';

import './DynamicRollupsHeaderRow.scss';

interface Props {
    columnsWidth: number;
    columns: DynamicRollupsRowConfig[];
    headerType: 'fixed' | 'scrollable';
}

const filterHeaderColumns = (columns: DynamicRollupsRowConfig[]) =>
    columns.reduce((acc, currentColumn) => {
        const { key: columnKey, isGroupStart, width: currentColumnWidth } = currentColumn;
        if (!isGroupStart) return acc;

        const groupColumns = columns.filter(({ groupKey }) => groupKey === columnKey);

        if (!groupColumns.length) {
            return [...acc, currentColumn];
        }

        const groupWidth = groupColumns.reduce((totalWidth, { width }) => totalWidth + (width ?? 0), 0);
        const totalGroupWidth = groupWidth + currentColumnWidth;

        return [
            ...acc,
            {
                ...currentColumn,
                width: totalGroupWidth,
            },
        ];
    }, [] as DynamicRollupsRowConfig[]);

function DynamicRollupsHeaderRow({ columnsWidth, columns, headerType }: Props) {
    const [columnsToDisplay, setColumnsToDisplay] = useState([] as DynamicRollupsRowConfig[]);

    useEffect(() => {
        const filteredColumns = filterHeaderColumns(columns);
        setColumnsToDisplay(filteredColumns);
    }, [columns, headerType]);

    return (
        <div style={{ width: columnsWidth }}>
            {columnsToDisplay.map((rowConfig: DynamicRollupsRowConfig, index) => {
                const { groupStartHeader, key, width } = rowConfig;

                return (
                    <HeaderColumn
                        key={`${key}-${kebabCase(groupStartHeader)}`}
                        className={cn('DynamicRollupsTable__header-data', `DynamicRollupsTable__header-data_${key}`, {
                            'DynamicRollupsTable__header-data__divider': !!index,
                        })}
                        style={{ width }}
                        label={groupStartHeader || ''}
                    />
                );
            })}
        </div>
    );
}

export default DynamicRollupsHeaderRow;
