import { ReportFilters } from 'buyplan-common';
import { PageView } from './appConfig';

export interface ReportsFiltersConfig {
    [key: string]: {
        requiredFilters: ReportFilters[];
        supplementaryFilters: ReportFilters[];
        allFilters?: ReportFilters[];
    };
}

export const reportsFiltersConfig: ReportsFiltersConfig = {
    [PageView.topStylesReport]: {
        requiredFilters: [ReportFilters.partner, ReportFilters.category, ReportFilters.division],
        supplementaryFilters: [
            ReportFilters.typeOfOrder,
            ReportFilters.gender,
            ReportFilters.age,
            ReportFilters.segment,
            ReportFilters.silhouette,
            ReportFilters.family,
            ReportFilters.model,
        ],
    },
};

reportsFiltersConfig[PageView.topStylesReport].allFilters = [
    ...reportsFiltersConfig[PageView.topStylesReport].requiredFilters,
    ...reportsFiltersConfig[PageView.topStylesReport].supplementaryFilters,
];
