"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOrder = exports.RollupsFilters = exports.ReportFilters = void 0;
var ReportFilters;
(function (ReportFilters) {
    ReportFilters["partner"] = "partner";
    ReportFilters["category"] = "category";
    ReportFilters["division"] = "division";
    ReportFilters["storeId"] = "storeId";
    ReportFilters["typeOfOrder"] = "typeOfOrder";
    ReportFilters["gender"] = "gender";
    ReportFilters["age"] = "age";
    ReportFilters["segment"] = "segment";
    ReportFilters["silhouette"] = "silhouette";
    ReportFilters["family"] = "family";
    ReportFilters["model"] = "model";
    ReportFilters["launchFlag"] = "launchFlag";
})(ReportFilters || (exports.ReportFilters = ReportFilters = {}));
var RollupsFilters;
(function (RollupsFilters) {
    RollupsFilters["partner"] = "partner";
    RollupsFilters["brand"] = "brand";
    RollupsFilters["consumer"] = "consumer";
    RollupsFilters["division"] = "division";
    RollupsFilters["emeaDimension"] = "emeaDimension";
    RollupsFilters["store"] = "store";
    RollupsFilters["typeOfOrder"] = "typeOfOrder";
    RollupsFilters["gender"] = "gender";
    RollupsFilters["age"] = "age";
    RollupsFilters["segment"] = "segment";
    RollupsFilters["launchFlag"] = "launchFlag";
    RollupsFilters["franchise"] = "franchise";
    RollupsFilters["merchClass"] = "merchClass";
    RollupsFilters["consumerUse"] = "consumerUse";
    RollupsFilters["silhouette"] = "silhouette";
    RollupsFilters["emeaSilo"] = "emeaSilo";
    RollupsFilters["emeaSubFranchise"] = "emeaSubFranchise";
    RollupsFilters["emeaFamily"] = "emeaFamily";
    RollupsFilters["emeaCollectionName"] = "emeaCollectionName";
    RollupsFilters["silhouetteType"] = "silhouetteType";
    RollupsFilters["thirdParty"] = "thirdParty";
    RollupsFilters["fieldsOfPlay"] = "fieldsOfPlay";
    RollupsFilters["features"] = "features";
    RollupsFilters["emeaFootballPlates"] = "emeaFootballPlates";
    RollupsFilters["emeaBusinessModel"] = "emeaBusinessModel";
})(RollupsFilters || (exports.RollupsFilters = RollupsFilters = {}));
exports.filterOrder = [
    RollupsFilters.partner,
    RollupsFilters.brand,
    RollupsFilters.consumer,
    RollupsFilters.division,
    RollupsFilters.emeaDimension,
    RollupsFilters.store,
    RollupsFilters.typeOfOrder,
    RollupsFilters.gender,
    RollupsFilters.age,
    RollupsFilters.segment,
    RollupsFilters.launchFlag,
    RollupsFilters.franchise,
    RollupsFilters.merchClass,
    RollupsFilters.consumerUse,
    RollupsFilters.silhouette,
    RollupsFilters.emeaSilo,
    RollupsFilters.emeaSubFranchise,
    RollupsFilters.emeaFamily,
    RollupsFilters.emeaCollectionName,
    RollupsFilters.silhouetteType,
    RollupsFilters.thirdParty,
    RollupsFilters.fieldsOfPlay,
    RollupsFilters.features,
    RollupsFilters.emeaFootballPlates,
    RollupsFilters.emeaBusinessModel,
];
