import React, { ChangeEvent, useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Check from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider } from '@material-ui/core/styles';
import { DropdownOption } from 'buyplan-common';
import { materialDropdownTheme } from '../../helpers/theme';

export interface MultiSelectProps {
    id?: string;
    options: DropdownOption[];
    onChange(value: (string | number)[]): void;
    onClose?: () => void;
    value: (string | number)[];
    label: string;
    disabled?: boolean;
    selectAll?: boolean;
    placeholder?: string;
}

export const SELECT_ALL = 'selectAllDropdown';
const NONE = 'none';

function MultiSelect({ onChange, value, options, label, id, disabled, selectAll, placeholder, onClose }: MultiSelectProps) {
    const [allSelected, setAllSelected] = useState<boolean>(false);

    useEffect(() => {
        const isAllSelected = options.every((option) => value.includes(option.value as string | number));
        setAllSelected(isAllSelected);
    }, [options, value]);

    const handleChange = (
        event: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        if ((event.target.value as (string | number)[]).includes(SELECT_ALL)) {
            const allOptions = options.map((option) => option.value);
            if (allSelected) {
                onChange([]);
            } else {
                onChange(allOptions);
            }
        } else {
            onChange(event.target.value as (string | number)[]);
        }
    };

    return (
        <ThemeProvider theme={materialDropdownTheme}>
            <InputLabel>{label}</InputLabel>
            <Select
                placeholder={placeholder}
                id={id}
                value={value}
                onChange={handleChange}
                onClose={onClose}
                disabled={disabled}
                IconComponent={ExpandMoreIcon}
                multiple
                renderValue={(selected) =>
                    (selected as string[])
                        .map((selectedVal) => {
                            const selectedOption = options.find((option) => option.value === selectedVal)?.label;
                            return selectedOption === '' ? NONE : selectedOption;
                        })
                        .filter((i) => i !== undefined)
                        .join(', ')
                }
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
            >
                {selectAll && <MenuItem value={SELECT_ALL}>{allSelected ? '- Deselect All -' : '- Select All -'}</MenuItem>}
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {!!value && value.includes(option.value) && <Check />}
                        {option.label === '' ? NONE : option.label}
                    </MenuItem>
                ))}
            </Select>
        </ThemeProvider>
    );
}

export default MultiSelect;
