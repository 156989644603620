import React from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';
import { DynamicRollupsRowConfig, DynamicRollupsObject } from 'buyplan-common';
import { getColumnWidth } from '../../../helpers/tableTools';
import HeaderColumn from '../../HeaderColumn/HeaderColumn';

import './DynamicRollupsSubHeaderRow.scss';

interface Props {
    columns: DynamicRollupsRowConfig[];
    totals: DynamicRollupsObject;
}

function DynamicRollupsSubHeaderRow({ columns, totals }: Props) {
    return (
        <div className="DynamicRollupsTable__subheader-row">
            {columns.map(({ key, label, groupStartHeader, getTotalsValue, getValue }) => {
                const headerLabel = groupStartHeader === label ? '' : label;
                const value = totals && headerLabel ? (totals[key as keyof typeof totals] as number | null) : null;

                return (
                    <div
                        key={`${key}-${kebabCase(headerLabel)}`}
                        style={{ width: getColumnWidth(columns, key) }}
                        className="DynamicRollupsTable__subheader-cell"
                    >
                        <HeaderColumn className={cn(`DynamicRollupsTable__subheader__${key}`)} label={headerLabel} />
                        <div
                            className={cn('DynamicRollupsTable__subheader-totals', {
                                'DynamicRollupsTable__subheader-totals--negative': (value ?? 0) < 0,
                            })}
                        >
                            {getTotalsValue ? getTotalsValue(value, false) : getValue?.(value, false)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default DynamicRollupsSubHeaderRow;
