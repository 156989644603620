import { HindsightYearType, MainFiltersExtended, TopStylesReportData } from 'buyplan-common';
import { pick } from 'lodash';
import { request } from '../api/api';

export const getThisYearTopStylesReport = (
    activeFilters: MainFiltersExtended,
    orderColumn: string,
    orderDirection: 'ASC' | 'DESC',
    signal?: AbortSignal
) => {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/this-year-top-styles`;
    const queryParams = {
        filter: pick(activeFilters, [
            'partner',
            'category',
            'division',
            'typeOfOrder',
            'gender',
            'age',
            'segment',
            'silhouette',
            'family',
            'model',
        ]),
        orderColumn,
        orderDirection,
    };
    const requestOptions = {
        method: 'GET',
        url: endpoint,
        signal,
        queryParams,
    };
    return request<{ data: TopStylesReportData }>(requestOptions, true);
};

export const getHindsightTopStylesReport = (
    activeFilters: MainFiltersExtended,
    yearType: HindsightYearType,
    orderColumn: string,
    orderDirection: 'ASC' | 'DESC',
    signal?: AbortSignal
) => {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/buyplan/reports/hindsight-top-styles`;
    const queryParams = {
        filter: pick(activeFilters, [
            'partner',
            'category',
            'division',
            'typeOfOrder',
            'gender',
            'age',
            'segment',
            'silhouette',
            'family',
            'model',
        ]),
        yearType,
        orderColumn,
        orderDirection,
    };
    const requestOptions = {
        method: 'GET',
        url: endpoint,
        signal,
        queryParams,
    };
    return request<{ data: TopStylesReportData }>(requestOptions, true);
};
