import React from 'react';
import { NavLink } from 'react-router-dom';
import { ChannelRole } from 'buyplan-common';
import useUserProfile from '../../selectors/useUserProfile';
import useHasChannelRole from '../../selectors/useHasChannelRole';
import Navigation from './Navigation';
import AssortmentSubnavigation from './Subnavigation/AssortmentSubnavigation';
import BuyplanSubnavigation from './Subnavigation/BuyplanSubnavigation';
import SettingsSubnavigation from './Subnavigation/SettingsSubnavigation';

function BuyplanNavigation() {
    const profile = useUserProfile();
    const isReadOnlyUser = useHasChannelRole(ChannelRole.readOnly);

    if (!profile) {
        return null;
    }

    return (
        <Navigation>
            <NavLink exact to="/buyplan">
                Home
            </NavLink>
            <span>
                <NavLink to="/buyplan/manage-assortment">Assortment</NavLink>
                {!isReadOnlyUser && <AssortmentSubnavigation />}
            </span>
            <span>
                <NavLink to="/buyplan/view-buy-plan">Buy Plan</NavLink>
                {!isReadOnlyUser && <BuyplanSubnavigation />}
            </span>
            <NavLink to="/buyplan/dynamic-rollups">Dynamic Rollups</NavLink>
            <NavLink to="/buyplan/top-styles-report">Top 15 styles</NavLink>
            <span>
                <NavLink to="/buyplan/exchange-rates">Settings</NavLink>
                {!isReadOnlyUser && <SettingsSubnavigation />}
            </span>
            <NavLink to="/buyplan/documentation">Documentation</NavLink>
        </Navigation>
    );
}

export default BuyplanNavigation;
