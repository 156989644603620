/* eslint-disable @typescript-eslint/no-explicit-any */
import { dynamicRollupsRows } from 'buyplan-common';

/*
 * Each migration step will take one version as input and return the next version as output.
 * (The key `3` means that it is the step which migrates from V1 to V2.)
 *
 * Migrations only run for users that have older versioned data. For new browser sessions
 * no migrations are run.
 *
 */
export const userMigrations = {
    3: (state: any) => ({
        ...state,
        settings: {
            ...state.settings,
            visibleDynamicRollupsColumnKeys: dynamicRollupsRows.map(({ key }) => key),
        },
    }),
};

/*
 * This is the current version and should match the latest version above.
 */
export const USER_PERSIST_VERSION = 3;
