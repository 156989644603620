export const HANDLE_GLOBAL_ERROR = 'HANDLE_GLOBAL_ERROR';
export const RESET_GLOBAL_ERROR = 'RESET_GLOBAL_ERROR';

export const CHANGE_SETTINGS = 'CHANGE_SETTINGS';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';

// Buyplan
export const GET_SEASON_DATA = 'GET_SEASON_DATA';
export const GET_SEASON_DATA_SUCCESS = 'GET_SEASON_DATA_SUCCESS';

export const NEW_SEASON = 'NEW_SEASON';
export const NEW_SEASON_FAILURE = 'NEW_SEASON_FAILURE';
export const NEW_SEASON_SUCCESS = 'NEW_SEASON_SUCCESS';

export const EDIT_SEASON = 'EDIT_SEASON';
export const EDIT_SEASON_FAILURE = 'EDIT_SEASON_FAILURE';
export const EDIT_SEASON_SUCCESS = 'EDIT_SEASON_SUCCESS';

export const GET_OPTION_COUNT_SUMMARY = 'GET_OPTION_COUNT_SUMMARY';
export const GET_OPTION_COUNT_FILTER_DATA = 'GET_OPTION_COUNT_FILTER_DATA';
export const GET_OPTION_COUNTS = 'GET_OPTION_COUNTS';

export const SET_ASSORTMENT = 'SET_ASSORTMENT';
export const ADD_ASSORTMENT = 'ADD_ASSORTMENT';

export const GET_ASSORTMENT_META = 'GET_ASSORTMENT_META';
export const GET_ASSORTMENT_META_SUCCESS = 'GET_ASSORTMENT_META_SUCCESS';
export const GET_ASSORTMENT_META_FAILURE = 'GET_ASSORTMENT_META_FAILURE';

export const UPDATE_ASSORTMENT_RECORD = 'UPDATE_ASSORTMENT_RECORD';
export const UPDATE_ASSORTMENT_RECORD_FAILURE = 'UPDATE_ASSORTMENT_RECORD_FAILURE';
export const UPDATE_ASSORTMENT_RECORD_SUCCESS = 'UPDATE_ASSORTMENT_RECORD_SUCCESS';

export const UPDATE_CLUSTERS_ASSORTMENT_RECORDS = 'UPDATE_CLUSTERS_ASSORTMENT_RECORDS';
export const UPDATE_CLUSTERS_ASSORTMENT_RECORDS_FAILURE = 'UPDATE_CLUSTERS_ASSORTMENT_RECORDS_FAILURE';
export const UPDATE_CLUSTERS_ASSORTMENT_RECORDS_SUCCESS = 'UPDATE_CLUSTERS_ASSORTMENT_RECORDS_SUCCESS';

export const UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT = 'UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT';
export const UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_FAILURE = 'UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_FAILURE';
export const UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_SUCCESS = 'UPDATE_MATERIAL_LOCK_FOR_PARTNER_ASSORTMENT_SUCCESS';

export const ADD_ASSORTMENT_RECORD = 'ADD_ASSORTMENT_RECORD';
export const ADD_ASSORTMENT_RECORD_FAILURE = 'ADD_ASSORTMENT_RECORD_FAILURE';
export const ADD_ASSORTMENT_RECORD_SUCCESS = 'ADD_ASSORTMENT_RECORD_SUCCESS';

export const UPDATE_ASSORTMENT_CLUSTER = 'UPDATE_ASSORTMENT_CLUSTER';
export const UPDATE_ASSORTMENT_CLUSTER_FAILURE = 'UPDATE_ASSORTMENT_CLUSTER_FAILURE';
export const UPDATE_ASSORTMENT_CLUSTER_SUCCESS = 'UPDATE_ASSORTMENT_CLUSTER_SUCCESS';

export const UPDATE_ASSORTMENT_MATERIAL = 'UPDATE_ASSORTMENT_MATERIAL';
export const UPDATE_ASSORTMENT_MATERIAL_FAILURE = 'UPDATE_ASSORTMENT_MATERIAL_FAILURE';
export const UPDATE_ASSORTMENT_MATERIAL_SUCCESS = 'UPDATE_ASSORTMENT_MATERIAL_SUCCESS';

// AGGREGATED MATERIAL
export const SET_BUYPLAN_AGGREGATED_MATERIALS = 'SET_BUYPLAN_AGGREGATED_MATERIALS';

export const UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES = 'UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES';
export const UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_SUCCESS = 'UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_SUCCESS';
export const UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_FAILURE = 'UPDATE_BUYPLAN_AGGREGATED_MATERIAL_STORES_FAILURE';

export const GET_BUYPLAN_AGGREGATED_MATERIAL = 'GET_BUYPLAN_AGGREGATED_MATERIAL';
export const GET_BUYPLAN_AGGREGATED_MATERIAL_SUCCESS = 'GET_BUYPLAN_AGGREGATED_MATERIAL_SUCCESS';
export const GET_BUYPLAN_AGGREGATED_MATERIAL_FAILURE = 'GET_BUYPLAN_AGGREGATED_MATERIAL_FAILURE';

export const REMOVE_BUYPLAN_AGGREGATED_MATERIAL = 'REMOVE_BUYPLAN_AGGREGATED_MATERIAL';

export const SET_PARTNER_REVIEWED_STATUS = 'SET_PARTNER_REVIEWED_STATUS';
export const SET_PARTNER_REVIEWED_STATUS_SUCCESS = 'SET_PARTNER_REVIEWED_STATUS_SUCCESS';
export const SET_PARTNER_REVIEWED_STATUS_FAILURE = 'SET_PARTNER_REVIEWED_STATUS_FAILURE';

export const SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES = 'SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES';
export const SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_SUCCESS = 'SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_SUCCESS';
export const SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_FAILURE = 'SET_BUYPLAN_AGGREGATED_MATERIAL_NOTES_FAILURE';

export const GET_BUYPLAN_META = 'GET_BUYPLAN_META';
export const GET_BUYPLAN_META_FAILURE = 'GET_BUYPLAN_META_FAILURE';
export const GET_BUYPLAN_META_SUCCESS = 'GET_BUYPLAN_META_SUCCESS';

export const GET_STORES = 'GET_STORES';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';

// User
export const USER_PROFILE = 'USER_PROFILE';
export const USER = 'USER';
export const USERS = 'USERS';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_SEASON = 'SET_SEASON';
export const TOGGLE_VISIBLE_COLUMN = 'TOGGLE_VISIBLE_COLUMN';
export const SET_VISIBLE_COLUMNS = 'SET_VISIBLE_COLUMNS';
export const ORDER_STORES = 'ORDER_STORES';
export const TOGGLE_FAVORITE_STORE = 'TOGGLE_FAVORITE_STORE';
export const CLEAR_FAVORITE_STORES = 'CLEAR_FAVORITE_STORES';
export const SET_RETAIL_SETTINGS = 'SET_RETAIL_SETTINGS';

export const GET_SIZE_MAPPINGS = 'GET_SIZE_MAPPINGS';
export const GET_SIZE_MAPPINGS_FAILURE = 'GET_SIZE_MAPPINGS_FAILURE';
export const GET_SIZE_MAPPINGS_SUCCESS = 'GET_SIZE_MAPPINGS_SUCCESS';
export const SELECT_SIZE_MAPPING = 'SELECT_SIZE_MAPPING';
export const DESELECT_ALL_SIZE_MAPPINGS = 'DESELECT_ALL_SIZE_MAPPINGS';
export const UPDATE_SIZE_MAPPING = 'UPDATE_SIZE_MAPPINGS';
export const UPDATE_SIZE_MAPPING_FAILURE = 'UPDATE_SIZE_MAPPINGS_FAILURE';
export const UPDATE_SIZE_MAPPING_SUCCESS = 'UPDATE_SIZE_MAPPINGS_SUCCESS';
export const SET_SIZE_FILTERS = 'SET_SIZE_FILTERS';

export const SET_ACTIVE_TOPLINE_FILTERS = 'SET_ACTIVE_TOPLINE_FILTERS';
export const SET_ACTIVE_TOPLINE_SLICERS = 'SET_ACTIVE_TOPLINE_SLICERS';

export const SET_ACTIVE_MAIN_FILTERS = 'SET_ACTIVE_MAIN_FILTERS';
export const SET_ACTIVE_SUB_FILTERS = 'SET_ACTIVE_SUB_FILTERS';
export const CLEAR_ACTIVE_SUB_FILTERS = 'CLEAR_ACTIVE_SUB_FILTERS';

export const GET_MARGINIMPACT = 'GET_MARGINIMPACT';
export const GET_MARGINIMPACT_FAILURE = 'GET_MARGINIMPACT_FAILURE';
export const GET_MARGINIMPACT_SUCCESS = 'GET_MARGINIMPACT_SUCCESS';
export const UPDATE_MARGINIMPACT = 'UPDATE_MARGINIMPACT';

// Impersonation
export const COPY_PRINCIPAL_USER = 'COPY_PRINCIPAL_USER';
export const REPLACE_USER_IN_ACTION = 'REPLACE_USER_IN_ACTION';
export const REPLACE_PRINCIPAL_USER = 'REPLACE_PRINCIPAL_USER';
export const RESET_IMPERSONATION_STATUS = 'RESET_IMPERSONATION_STATUS';

// Events
export const SET_EVENT = 'SET_EVENT';
export const CLEAR_EVENT = 'CLEAR_EVENT';

// Users notifications
export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const SET_FX_RATES_PROCESS_STATUS = 'SET_FX_RATES_PROCESS_STATUS';
export const RESET_FX_RATES_PROCESS_STATUS = 'RESET_FX_RATES_PROCESS_STATUS';
export const SET_SNAPSHOT_DATA = 'SET_SNAPSHOT_DATA';
export const GET_LATEST_SNAPSHOT_DATA = 'GET_LATEST_SNAPSHOT_DATA';

// Dynamic Rollups
export const GET_DYNAMIC_ROLLUPS = 'GET_DYNAMIC_ROLLUPS';
export const GET_DYNAMIC_ROLLUPS_FAILURE = 'GET_DYNAMIC_ROLLUPS_FAILURE';
export const GET_DYNAMIC_ROLLUPS_SUCCESS = 'GET_DYNAMIC_ROLLUPS_SUCCESS';
export const CLEANUP_ROLLUPS_FILTERS = 'CLEANUP_ROLLUPS_FILTERS';
