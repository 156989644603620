"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncJobStatusEnum = void 0;
var AsyncJobStatusEnum;
(function (AsyncJobStatusEnum) {
    AsyncJobStatusEnum["started"] = "STARTED";
    AsyncJobStatusEnum["inProgress"] = "IN_PROGRESS";
    AsyncJobStatusEnum["completed"] = "COMPLETED";
    AsyncJobStatusEnum["failed"] = "FAILED";
})(AsyncJobStatusEnum || (exports.AsyncJobStatusEnum = AsyncJobStatusEnum = {}));
