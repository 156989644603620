import React, { useState, useEffect, useRef } from 'react';
import { sum } from 'lodash';
import { DynamicRollupsRowConfig, DynamicRollupsData } from 'buyplan-common';
import AutoSizer from 'react-virtualized-auto-sizer';
import { List, CellMeasurer, CellMeasurerCache, ListRowProps, ScrollSync } from 'react-virtualized';
import DynamicRollupsHeaderRow from '../DynamicRollupsHeader/DynamicRollupsHeaderRow';
import DynamicRollupsSubHeaderRow from '../DynamicRollupsSubHeaderRow/DynamicRollupsSubHeaderRow';
import DynamicRollupsRow from '../DynamicRollupsRow/DynamicRollupsRow';
import { getColumnRangeWidth, getColumnWidth } from '../../../helpers/tableTools';

import './DynamicRollupsTable.scss';

const ROW_HEIGHT = 40;

interface Props {
    report: DynamicRollupsData;
    slicerColumns: DynamicRollupsRowConfig[];
    dynamicRollupsColumns: DynamicRollupsRowConfig[];
}

const calculateColumnWidths = (
    columnType: string,
    fixedColumns: DynamicRollupsRowConfig[],
    scrollableColumns: DynamicRollupsRowConfig[]
) => {
    const totalColumnWidth = getColumnRangeWidth([...fixedColumns, ...scrollableColumns]);

    const fixedColumnsWidth = sum(fixedColumns.map(({ key }) => getColumnWidth(fixedColumns, key))) + 20;
    const scrollableColumnsWidth = totalColumnWidth - fixedColumnsWidth + 80; // Needs extra buffer for padding and scrollbar

    if (columnType === 'fixed') {
        return fixedColumnsWidth;
    }
    return scrollableColumnsWidth;
};

function DynamicRollupsTable({ report, slicerColumns, dynamicRollupsColumns }: Props) {
    const { objects, totals } = report;
    const [fixedColumns, setFixedColumns] = useState([] as DynamicRollupsRowConfig[]);
    const [scrollableColumns, setScrollableColumns] = useState([] as DynamicRollupsRowConfig[]);

    const fixedListRef = useRef(null);
    const scrollableListRef = useRef(null);
    const columnsCache = {
        fixed: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
        scrollable: new CellMeasurerCache({ defaultHeight: ROW_HEIGHT, fixedWidth: true }),
    };

    useEffect(() => {
        setFixedColumns(slicerColumns);
        setScrollableColumns(dynamicRollupsColumns);
    }, [slicerColumns, dynamicRollupsColumns]);

    const renderColumns = ({ index, key, parent }: ListRowProps, type: 'fixed' | 'scrollable') => {
        const row = objects[index];
        return (
            <CellMeasurer cache={columnsCache[type]} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <DynamicRollupsRow key={key} row={row} columns={type === 'fixed' ? fixedColumns : scrollableColumns} />
            </CellMeasurer>
        );
    };

    const fixedColumnsWidth = calculateColumnWidths('fixed', fixedColumns, scrollableColumns);
    const scrollableColumnsWidth = calculateColumnWidths('scrollable', fixedColumns, scrollableColumns);
    const rowCount = objects.length;

    return (
        <ScrollSync>
            {({ onScroll, scrollTop }) => (
                <div className="infinite-scroll-list">
                    <div className="scroll-smoother" /> {/* Needed to keep AutoSizer in sync while scrolling */}
                    <AutoSizer>
                        {({ width, height }) => (
                            <div className="DynamicRollupsTable__container">
                                <div className="DynamicRollupsTable__fixed-container">
                                    <div className="DynamicRollupsTable__header" style={{ width: fixedColumnsWidth }}>
                                        <DynamicRollupsHeaderRow
                                            columnsWidth={fixedColumnsWidth}
                                            columns={fixedColumns}
                                            headerType="fixed"
                                        />
                                    </div>
                                    <div className="DynamicRollupsTable__subheader" style={{ width: fixedColumnsWidth }}>
                                        <DynamicRollupsSubHeaderRow columns={fixedColumns} totals={totals} />
                                    </div>
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={columnsCache.fixed}
                                        width={fixedColumnsWidth}
                                        height={!rowCount ? 0 : height}
                                        rowCount={rowCount}
                                        rowHeight={columnsCache.fixed.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) => renderColumns(listRowProps, 'fixed')}
                                        className="DynamicRollupsTable__item"
                                        ref={fixedListRef}
                                        overscanRowCount={0}
                                    />
                                </div>
                                <div
                                    className="DynamicRollupsTable__scrollable-container"
                                    style={{ width: width - fixedColumnsWidth }}
                                >
                                    <div className="DynamicRollupsTable__header" style={{ width: scrollableColumnsWidth }}>
                                        <DynamicRollupsHeaderRow
                                            columnsWidth={scrollableColumnsWidth}
                                            columns={scrollableColumns}
                                            headerType="scrollable"
                                        />
                                    </div>
                                    <div
                                        className="DynamicRollupsTable__subheader"
                                        style={{ width: scrollableColumnsWidth }}
                                    >
                                        <DynamicRollupsSubHeaderRow columns={scrollableColumns} totals={totals} />
                                    </div>
                                    <List
                                        scrollTop={scrollTop}
                                        onScroll={onScroll}
                                        deferredMeasurementCache={columnsCache.scrollable}
                                        width={scrollableColumnsWidth}
                                        height={height}
                                        rowCount={rowCount}
                                        rowHeight={columnsCache.scrollable.rowHeight}
                                        rowRenderer={(listRowProps: ListRowProps) =>
                                            renderColumns(listRowProps, 'scrollable')
                                        }
                                        className="DynamicRollupsTable__item"
                                        ref={scrollableListRef}
                                        overscanRowCount={0}
                                    />
                                </div>
                            </div>
                        )}
                    </AutoSizer>
                </div>
            )}
        </ScrollSync>
    );
}

export default DynamicRollupsTable;
