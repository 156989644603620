import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { filterOrder, getLabel, TopLineFilterKeys, TopLineFilterOptions } from 'buyplan-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Dropdown from '../../Dropdown/Dropdown';
import './SlicingFilters.scss';
import useUserSettings from '../../../selectors/useUserSettings';
import { setActiveTopLineSlicers } from '../../../actions/user';
import { PageView } from '../../../constants/appConfig';

interface Props {
    activeSlicers: TopLineFilterKeys;
}

function SlicingFilters({ activeSlicers }: Props): JSX.Element {
    const dispatch = useDispatch();
    const { activeChannelId } = useUserSettings();
    const [selectedSlicers, setSelectedSlicers] = useState(activeSlicers ?? []);
    const [totalSlicers, setTotalSlicers] = useState(activeSlicers?.length || 1);

    const handleChange = (slicerIndex: number) => (value: keyof TopLineFilterOptions) => {
        const updatedSlicers = [...selectedSlicers];
        updatedSlicers[slicerIndex] = value;
        return setSelectedSlicers(updatedSlicers);
    };

    useEffect(() => {
        if (selectedSlicers.length > 0) {
            dispatch(setActiveTopLineSlicers(selectedSlicers, activeChannelId, PageView.dynamicRollups));
        }
    }, [activeChannelId, dispatch, selectedSlicers]);

    const handleAddingSlicer = () => {
        // Maximum of 5 data slicers
        if (totalSlicers < 5) {
            setTotalSlicers(totalSlicers + 1);
        }
    };

    const handleRemovingSlicer = () => {
        if (totalSlicers === selectedSlicers.length) {
            const updatedSlicers = selectedSlicers.slice(0, -1);
            setSelectedSlicers(updatedSlicers);
        }
        setTotalSlicers(totalSlicers - 1);
    };

    return (
        <div className="SlicingFilters">
            {Array.from({ length: totalSlicers }, (_, index) => (
                <div className="SlicingFilters__select" key={`slicer=${index}`}>
                    <Dropdown
                        options={filterOrder.map((slicerOption) => ({
                            label: getLabel(slicerOption),
                            value: slicerOption,
                        }))}
                        value={selectedSlicers[index]}
                        onChange={handleChange(index)}
                        label="Slice by:"
                    />
                </div>
            ))}
            {totalSlicers > 1 && (
                <button className="SlicingFilters__removeButton" type="button" onClick={handleRemovingSlicer}>
                    <FontAwesomeIcon icon={faCircleMinus as IconProp} />
                </button>
            )}
            {totalSlicers < 5 && (
                <button
                    className="SlicingFilters__addButton"
                    type="button"
                    onClick={handleAddingSlicer}
                    disabled={totalSlicers > selectedSlicers.length}
                >
                    <FontAwesomeIcon icon={faCirclePlus as IconProp} />
                </button>
            )}
        </div>
    );
}

export default SlicingFilters;
