import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { getLabel, RollupsFilters, DynamicRollupsMetaData, TopLineFilterSelections, filterOrder } from 'buyplan-common';
import MultiSelect from '../../Dropdown/MultiSelect';
import Loader from '../../Loader/Loader';
import './ToplineFilters.scss';
import { setActiveTopLineFilters } from '../../../actions/user';
import { PageView } from '../../../constants/appConfig';
import useUserSettings from '../../../selectors/useUserSettings';

interface Props {
    filtersLoading: boolean;
    filtersData?: DynamicRollupsMetaData;
    activeFilters: TopLineFilterSelections;
}

function TopLineFilters({ filtersData, activeFilters, filtersLoading }: Props): JSX.Element {
    const dispatch = useDispatch();
    const { activeChannelId } = useUserSettings();
    const [selectedFilters, setSelectedFilters] = useState(activeFilters);

    const handleChange = (key: keyof typeof RollupsFilters) => (values: string[]) => {
        const selectedFilter = {
            [key]: values.length > 0 ? values : undefined,
        };
        setSelectedFilters({ ...selectedFilters, ...selectedFilter });
    };

    useEffect(() => {
        if (!isEmpty(selectedFilters)) {
            dispatch(setActiveTopLineFilters(selectedFilters, activeChannelId, PageView.dynamicRollups));
        }
    }, [activeChannelId, dispatch, selectedFilters]);

    if (filtersLoading) {
        return <Loader />;
    }

    return (
        <div className="ToplineFilters">
            {filterOrder.map((filter) => (
                <div className="ToplineFilters--filter" key={filter}>
                    <MultiSelect
                        selectAll
                        options={filtersData?.filters[filter] || []}
                        onChange={handleChange(filter)}
                        value={selectedFilters?.[filter] ?? []}
                        label={getLabel(filter)}
                        disabled={
                            filtersData?.filters && !Object.prototype.hasOwnProperty.call(filtersData?.filters, filter)
                        }
                        id={filter}
                    />
                </div>
            ))}
        </div>
    );
}

export default TopLineFilters;
