import { DynamicRollupsRowConfig, DynamicRollupsData } from 'buyplan-common';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

export const prepareDynamicRollupsExport = async (
    slicerColumns: DynamicRollupsRowConfig[],
    dynamicRollupsColumns: DynamicRollupsRowConfig[],
    data: DynamicRollupsData,
    filename: string
) => {
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('Sheet 1', {});
    const reportSubheaders: { key: string; header: string; width: number }[] = [];
    const reportGroupings: string[] = [];
    const mergedColumns = slicerColumns.concat(dynamicRollupsColumns);

    mergedColumns.forEach((row) => {
        reportSubheaders.push({
            key: row.key,
            header: row.label,
            width: Math.max(row.label.length + 5, (row.groupStartHeader || '').length + 5),
        });

        if (row.groupStartHeader) {
            reportGroupings.push(row.groupStartHeader);
        } else if (row.groupKey) {
            reportGroupings.push(mergedColumns.filter((i) => i.key === row.groupKey)?.[0].groupStartHeader || '');
        }
    });
    sheet.columns = reportSubheaders;

    let currentRow = 4;
    data.objects.forEach((reportItem) => {
        sheet.getRow(currentRow).values = reportItem;
        sheet.getRow(currentRow).font = { size: 14, name: 'Calibri' };
        currentRow++;
    });

    // Adding grouping to first row and totals to third row.
    sheet.getRow(2).values = sheet.getRow(1).values;
    sheet.getRow(1).values = reportGroupings;
    sheet.getRow(3).values = data.totals;

    // styling grouping row
    const groupingRows = sheet.getRow(1);
    groupingRows.eachCell((_cell, col) => {
        groupingRows.getCell(col).font = { bold: true, size: 14, name: 'Calibri' };
    });

    // styling sub-header row
    const subheaderRow = sheet.getRow(2);
    subheaderRow.eachCell((_cell, col) => {
        subheaderRow.getCell(col).font = { bold: true, size: 14, name: 'Calibri' };
    });

    // styling totals row
    const totalsRow = sheet.getRow(3);
    totalsRow.eachCell((_cell, col) => {
        totalsRow.getCell(col).font = { bold: true, size: 14, name: 'Calibri' };
    });

    // merging grouping cells
    let initialIndex = 0;
    let currentGrouping = reportGroupings[initialIndex];
    for (let currentIndex = 0; currentIndex <= reportGroupings.length; currentIndex++) {
        const cur = reportGroupings[currentIndex];
        if (currentGrouping !== cur) {
            sheet.mergeCells(1, initialIndex + 1, 1, currentIndex);
            currentGrouping = cur;
            initialIndex = currentIndex;
        }
    }

    // merging headers and totals for slicerColumns columns
    sheet.mergeCells(2, 1, 2, slicerColumns.length);
    sheet.mergeCells(3, 1, 3, slicerColumns.length);
    sheet.getCell(2, 1).value = '';
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${filename}.xlsx`);
};
