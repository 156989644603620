import { DynamicRollupsData } from 'buyplan-common';
import * as dynamicRollupsService from '../services/dynamicRollupsService';
import { PageView } from '../constants/appConfig';
import { handleGlobalError } from './globalError';
import { GET_DYNAMIC_ROLLUPS, GET_DYNAMIC_ROLLUPS_FAILURE, GET_DYNAMIC_ROLLUPS_SUCCESS } from './actionTypes';
import { AnyDispatch, GetRootState } from './types';

const getDynamicRollupsFailure = (error: Error) =>
    ({
        type: GET_DYNAMIC_ROLLUPS_FAILURE,
        error,
    } as const);

export type GetDynamicRollupsFailureAction = ReturnType<typeof getDynamicRollupsFailure>;

const getDynamicRollupsSuccess = (data: DynamicRollupsData) =>
    ({
        type: GET_DYNAMIC_ROLLUPS_SUCCESS,
        data,
    } as const);

export type GetDynamicRollupsSuccessAction = ReturnType<typeof getDynamicRollupsSuccess>;

export const getDynamicRollups = () => async (dispatch: AnyDispatch, getState: GetRootState) => {
    try {
        const { activeTopLineFilters, activeChannelId, activeSeasonId } = getState().user.settings;
        const { slicers, mainFilters } = activeTopLineFilters[`${activeChannelId}|${PageView.dynamicRollups}`] ?? {
            mainFilters: {},
            slicers: [],
        };

        dispatch({ type: GET_DYNAMIC_ROLLUPS });
        const response = await dynamicRollupsService.getDynamicRollups({
            channelId: activeChannelId,
            seasonId: activeSeasonId,
            filters: mainFilters,
            slicers,
        });
        dispatch(getDynamicRollupsSuccess(response));
    } catch (err: unknown) {
        const error = err as Error;
        dispatch(handleGlobalError(error.message));
    }
};

export type GetDynamicRollupsAction = { type: typeof GET_DYNAMIC_ROLLUPS };

export type DynamicRollupsActions =
    | GetDynamicRollupsAction
    | GetDynamicRollupsSuccessAction
    | GetDynamicRollupsFailureAction;
