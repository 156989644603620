import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { dynamicRollupsRows } from 'buyplan-common';
import SettingsModal from '../../SettingsModal/SettingsModal';
import SettingsReportColumns, { ColumnsGroup } from '../../UserSettings/SettingsReportColumns';

interface Props {
    onClose(): void;
}

const columnsConfig = dynamicRollupsRows
    .reduce((acc, { isGroupStart, key, label, isFixed, groupStartHeader }) => {
        const group =
            isGroupStart || !acc.length
                ? { groupKey: key, groupLabel: groupStartHeader ?? label, columns: [] }
                : acc[acc.length - 1];
        const groupWithNewColumn = isFixed ? group : { ...group, columns: [...group.columns, { key, label }] };

        return isGroupStart ? [...acc, groupWithNewColumn] : [...acc.slice(0, -1), groupWithNewColumn];
    }, [] as ColumnsGroup[])
    .filter(({ columns }) => !!columns.length);

function DynamicRollupsSettings({ onClose }: Props) {
    return (
        <SettingsModal onClose={onClose}>
            <Tabs>
                <TabList>
                    <Tab>
                        <h3>DISPLAY COLUMNS</h3>
                    </Tab>
                </TabList>
                <TabPanel>
                    <SettingsReportColumns columnGroups={columnsConfig} columnSetting="visibleDynamicRollupsColumnKeys" />
                </TabPanel>
            </Tabs>
        </SettingsModal>
    );
}

export default DynamicRollupsSettings;
