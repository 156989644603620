import React from 'react';

export default function () {
    return (
        <div>
            <div>
                <h2>Import Assortment Files</h2>
                <p>
                    The BuyPlan application relies on several files being uploaded before the assortment and assembly of the
                    buy plan can begin. Below you will find the necessary files needed to start with the assortment planning.
                </p>
                <p>
                    <em>
                        <b>NOTE:</b> only super users can import Assortment files.
                    </em>
                </p>
                <div className="ImportBuyPlanFiles__fileSection">
                    <h3>Import Option Count File</h3>
                    <p>
                        At the start of a season, you can upload an option count file to help start the assortment process.
                        This file is crucial in giving us not only the option counts, but also the list of stores to include
                        in the assortment.
                    </p>
                    <p>
                        The sheet should be visible and named <code>Sheet1</code>.
                    </p>
                    <br />
                    <h4>
                        <b>Columns for NDS, Digital:</b>
                    </h4>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Partner</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">STORE NUMBER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">STORE NAME</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CATEGORY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">DIVISION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">OPTIONS TO BUY</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">COUNTRY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Must be a valid country</td>
                            </tr>
                            <tr>
                                <th scope="row">DIFFERENTIATOR 1</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>
                                    Must be a valid differentiator to define a cluster. Combination of differentiator 1,
                                    differentiator 2 and size will be used to define a cluster. For example, for NDS it could
                                    be values of concept or territory{' '}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">DIFFERENTIATOR 2</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>
                                    Must be a valid differentiator to define a cluster. Combination of differentiator 1,
                                    differentiator 2 and size will be used to define a cluster. For example, for NDS it could
                                    be values of concept or territory{' '}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">SIZE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Must be XXXS, XXS, XS, S, M, L, XL, XXL or XXXL. Used to define a cluster</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <h4>Modifying/Deleting the Option Count file</h4>
                    <p>
                        Re-uploading the Option Count file will reset the option count data for the given channel and season,
                        including manual adjustments to assortments and buys data. It will replace option counts. If a new
                        store is added it will add the store target row for the new store. Clusters will be re-created and
                        re-assigned to stores based on any changes in the file.
                    </p>
                    <p>
                        <em>
                            <b>NOTE:</b> The target for the new store should be added from the{' '}
                            <strong>Sales store targets</strong> page.
                        </em>
                    </p>
                    <p>
                        Deleting the Option Count file will remove the option count data for the given channel and season.
                        This includes manual adjustments to assortments and buys data.
                    </p>
                </div>
                <div className="ImportBuyPlanFiles__fileSection">
                    <h3>Import Merchandise Files</h3>
                    <p>
                        After importing your option counts file, the application knows the targeted number for a
                        store/division/category level. The next step is to give the application the materials that are going
                        to be assigned to those stores and included in the Buyplan. This is done by uploading the merchandise
                        files given by merch;
                    </p>
                    <p>
                        The sheet should be visible, named <code>2. ASSORTMENT PLAN</code> and the Material codes should be
                        in column <code>D</code>.
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Excel Header</th>
                                <th scope="col">Type</th>
                                <th scope="col">Column required</th>
                                <th scope="col">Data required</th>
                                <th scope="col">Format</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">.COM ADOPTION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>YES or NO</td>
                            </tr>
                            <tr>
                                <th scope="row">3RD PARTY VENDOR (LICENSEE)</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">AA</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">AGE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>ADULT, YOUTH, GRADE SCHOOL, PRES CHOOL, TODDLER, INFANT or NEWBORN</td>
                            </tr>
                            <tr>
                                <th scope="row">ALLOCATED</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">BRAND</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Must be one of the following valid brands: NIKE, JORDAN</td>
                            </tr>
                            <tr>
                                <th scope="row">BUY COMMENTS</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CARRY IN FROM PREVIOUS SEASON</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CARRY OVER TO NEXT SEASON</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CC CONSUMER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>MENS, WOMENS, KIDS or JORDAN</td>
                            </tr>
                            <tr>
                                <th scope="row">CC SEGMENT</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>LIFESTYLE or PERFORMANCE</td>
                            </tr>
                            <tr>
                                <th scope="row">COLOR CODE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">COLOR DESCRIPTION LONG</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">COLOUR RANK</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>Minimum 1</td>
                            </tr>
                            <tr>
                                <th scope="row">CONSUMER USE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">CORE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">DIGITAL STYLE RANK</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">DIMENSION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">DISTRIBUTION INTENT</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">DIVISION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>FOOTWEAR, APPAREL or EQUIPMENT</td>
                            </tr>
                            <tr>
                                <th scope="row">DTC CONDENSED CATEGORY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA BRAND ARCHITECTURE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA BUSINESS MODEL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA COLLECTION NAME</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA DIMENSION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA FAMILY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA FOOTBALL PLATES</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA SILO</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">EMEA SUB FRANCHISE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">FAMILY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">FEATURES (SUSTAINABILITY TIER)</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">FIELDS OF PLAY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">FIRST OFFER DATE</th>
                                <td>Date</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>MM/DD/YYYY</td>
                            </tr>
                            <tr>
                                <th scope="row">FRANCHISE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">GEL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">GENDER</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>WOMENS, MENS, UNISEX, KIDS UNISEX, GIRLS or BOYS</td>
                            </tr>
                            <tr>
                                <th scope="row">GPH CATEGORY</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">GRADE (A, B, C)</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>A, B or C</td>
                            </tr>
                            <tr>
                                <th scope="row">HEAT LEVEL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>LOW, MEDIUM, HIGH, VERY HIGH</td>
                            </tr>
                            <tr>
                                <th scope="row">IPP</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>YES or NO</td>
                            </tr>
                            <tr>
                                <th scope="row">ITEM DESCRIPTION</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">KEY INITIATIVE 1</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">KEY INITIATIVE 2</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">KEY INITIATIVE 3</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">KEY LOOK STYLE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">LAUNCH DATE</th>
                                <td>Date</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>MM/DD/YYYY</td>
                            </tr>
                            <tr>
                                <th scope="row">LAUNCH FLAG</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>C, D, L, X, 0 or blank</td>
                            </tr>
                            <tr>
                                <th scope="row">LIFECYCLE MANAGEMENT</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">LIKE4LIKE STYLE COLOR</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">LOCAL SPORTING MOMENT</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MATERIAL CODE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>
                                    Material Code format:
                                    <br /> XXXXXX-XXX where X is A-Z or 0-9
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MEMBERSHIP LAUNCH DATE</th>
                                <td>Date</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>MM/DD/YYYY</td>
                            </tr>
                            <tr>
                                <th scope="row">MEMBERSHIP</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MERCH CLASS</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MINDSET</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MINIMUM CREDIBLE ASSORTMENT</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">SILHOUETTE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">MODEL</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">NIKE ID</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>YES or NO</td>
                            </tr>
                            <tr>
                                <th scope="row">PACK</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">RETAIL PRICE EURO</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Price must be greater than 0</td>
                            </tr>
                            <tr>
                                <th scope="row">RETAIL PRICE GBP</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Price must be greater than 0</td>
                            </tr>
                            <tr>
                                <th scope="row">SEASONAL CATEGORY STORIES</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">SILHOUETTE TYPE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">SNEAKRS OR UNLACED</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">SNKRS APP</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>YES or NO</td>
                            </tr>
                            <tr>
                                <th scope="row">SNKRS WEB</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>YES or NO</td>
                            </tr>
                            <tr>
                                <th scope="row">STORES FLOW</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>FlowX_FlowX where X = 1,2,3</td>
                            </tr>
                            <tr>
                                <th scope="row">DIGITAL FLOW</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>FlowX_FlowX where X = 1,2,3</td>
                            </tr>
                            <tr>
                                <th scope="row">STYLE CODE</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">STYLE RANK</th>
                                <td>Integer</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">VAT %</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>no</td>
                                <td>Minimum 0, maximum 1, default 0.21</td>
                            </tr>
                            <tr>
                                <th scope="row">WEEK OF CAMPAIGN</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                            <tr>
                                <th scope="row">WHOLESALE COST EURO</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Wholesale cost must be greater than 0</td>
                            </tr>
                            <tr>
                                <th scope="row">WHOLESALE COST GBP</th>
                                <td>Number</td>
                                <td>yes</td>
                                <td>yes</td>
                                <td>Wholesale cost must be greater than 0</td>
                            </tr>
                            <tr>
                                <th scope="row">ZERO STAR STORIES</th>
                                <td>Text</td>
                                <td>yes</td>
                                <td>no</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3>Modifying/Deleting Merchandise Files</h3>
                    <p>
                        When modifying or deleting already uploaded files we have put in some safe guards that will help you
                        protect your manual adjustments. In the case that you wish to modify or delete a file that has had
                        materials modified within the assortment you will get an alert popup that asks you to verify what you
                        are about to do. If you choose to <strong>MODIFY OR DELETE</strong> a file that has had changes occur
                        than that data will be <strong>LOST</strong>.
                    </p>
                    <p>
                        Re-uploading a Merchandise file will reset any data related to the provided materials for the given
                        channel and season. It will update the materials, buys, margin impact, last year data, minimum buys,
                        and allocations.
                    </p>
                    <p>
                        Last year data (like4like) will be set for the new materials added to the re-upload file. Last year
                        data (like4like) will not be reset after for existing materials.
                    </p>
                </div>
            </div>
        </div>
    );
}
